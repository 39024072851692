<script lang="ts" setup>
import { LocaleObject } from '@nuxtjs/i18n/dist/runtime/composables'
import { getSeoUrls } from '@shopware-pwa/api-client'
import VueSelect from 'vue-select'
import { createPopper } from '@popperjs/core'
import { defaultLocale } from '~/i18n/config'

const { apiInstance } = useShopwareContext()

const { languageCode } = useLocale()
const { locales, setLocale } = useI18n()
const { setLoading } = useLoadingStore()
const { changeShopLanguage } = useShopLanguages()
const store = useLanguageSwitcherStore()
const links = computed(() => store.drupal.links)
const entityId = computed(() => store.shopware.entityId)
const isDrupal = computed(() => Boolean(store.drupal.links.length))
const isShopware = computed(() => Boolean(store.shopware.entityId))

interface Option {
  label: string
  value: string
}
const options = (locales.value as LocaleObject[]).map(
  ({ name, code }: LocaleObject): Option => ({
    label: name as string,
    value: code as string,
  }),
)

const current = computed(() => options.find((option) => option.value === languageCode.value))

const onChange = async ({ value: code }: Option) => {
  // set loading state
  setLoading(true)

  const languageId = await changeShopLanguage(code)

  // navigate to correct page
  if (isDrupal.value) {
    return navigateTo(links.value.find((link) => link.locale === code)?.path)
  }

  if (isShopware.value) {
    const response = await getSeoUrls(entityId.value, languageId, apiInstance)
    const path =
      code === defaultLocale ? `/${response.elements[0].seoPathInfo}` : `/${code}/${response.elements[0].seoPathInfo}`

    return navigateTo(path)
  }

  return await setLocale(code)
}

const computePosition = (
  dropdownList: HTMLUListElement,
  component: Vue,
  { width }: { width: string; top: string; left: string },
) => {
  /**
   * We need to explicitly define the dropdown width since
   * it is usually inherited from the parent with CSS.
   */
  dropdownList.style.width = width

  /**
   * Here we position the dropdownList relative to the $refs.toggle Element.
   *
   * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
   * the dropdownList overlap by 1 pixel.
   *
   * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
   * wrapper so that we can set some styles for when the dropdown is placed
   * above.
   */
  const popper = createPopper(component.$refs.toggle as HTMLElement, dropdownList, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -40],
        },
      },
      {
        name: 'toggleClass',
        enabled: true,
        phase: 'write',
        fn({ state }) {
          component.$el.classList.toggle('drop-up', state.placement === 'top')
        },
      },
    ],
  })

  /**
   * To prevent memory leaks Popper needs to be destroyed.
   * If you return function, it will be called just before dropdown is removed from DOM.
   */
  return () => popper.destroy()
}

// :dropdown-should-open="() => true"
</script>

<template>
  <div>
    <VueSelect
      :clearable="false"
      :options="options"
      :model-value="current"
      :searchable="false"
      :append-to-body="true"
      :calculate-position="computePosition"
      @option:selected="onChange"
    >
      <template #selected-option="{ value }">
        <span class="label">
          {{ (value as string).toUpperCase() }}
        </span>
      </template>
      <template #open-indicator="{ toggleAttributes }">
        <span v-bind="toggleAttributes as {}"><Icon name="dropdown" /></span>
      </template>
    </VueSelect>
  </div>
</template>
<style lang="scss" scoped>
.v-select {
  --vs-border-radius: 0;
  --vs-border-color: var(--c-blue-40);

  :deep(.vs__dropdown-toggle) {
    padding: 5px 12px 5px 0;
    flex-direction: row-reverse;
  }
  :deep(.vs__selected-options) {
    padding: 0;
  }
  :deep(.vs__selected) {
    padding: 0;
    margin: 0;
    border: 0;
    color: var(--c-primary);
  }
  :deep(.vs__actions) {
    padding: 0;
    svg {
      display: block;
    }
  }
}

.vs--single.vs--open :deep(.vs__selected) {
  position: relative;
}
.vs--unsearchable:not(.vs--disabled) :deep(.vs__search) {
  padding: 0;
  position: absolute;
  height: 0;
  width: 0;
  overflow: hidden;
}
</style>
<style lang="scss">
.vs__dropdown-menu {
  // bottom: -40px !important;
  padding: 32px 0;
  border: none;
  color: var(--c-primary);
  box-shadow: var(--shadow);
  min-width: 150px;
}
.vs__dropdown-option {
  padding: 9px 12px 11px 32px;
  font-size: 18px;
}
.vs__dropdown-option--highlight {
  color: var(--c-white);
  background-color: var(--c-primary);
}
.vs__dropdown-option--selected {
  display: flex;
  align-items: center;
  color: var(--c-primary);
  background-color: var(--c-background-medium);
}
</style>
